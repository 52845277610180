import { Box, Text } from "@chakra-ui/core"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/Layout"
import Card from "../components/YuGiOh/Card/Card"
import Header from "../components/YuGiOh/Header/Header"
import favicon from "../images/favicon.png"
import "./index.scss"

const YuGiOhIndexPage = () => {
  const [level, setLevel] = useState(12)
  const [atk, setAtk] = useState(0)
  const [cardId, setCardId] = useState(null)
  const [cardName, setCardName] = useState("")
  const backgroundYugioh = require("../images/bg.png")
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Layout>
        <Box
          position="fixed"
          zIndex={0}
          top={0}
          left={0}
          height="100vh"
          width="100vw"
          bgImage={`url("${backgroundYugioh}")`}
          bgPosition="center"
          bgRepeat="no-repeat"
          bgSize="cover"
        />

        <Header
          setLevel={setLevel}
          level={level}
          atk={atk}
          setAtk={setAtk}
          monster={true}
          trapCard={false}
          spellCard={false}
          cardId={cardId}
          setName={setCardName}
        />
        <Card
          level={level}
          atk={atk}
          monster={true}
          cardId={cardId}
          setCardId={setCardId}
          cardName={cardName}
        />
        <Box position="relative" textAlign="center" mt={10}>
          <a
            href="https://www.instagram.com/0xpierre.dev/"
            target="_blank"
            rel="noreferrer"
          >
            <Text color="white" fontSize={15} fontWeight={600}>
              @0xpierre.dev - {new Date().getFullYear()}
            </Text>
          </a>
        </Box>
      </Layout>
    </>
  )
}

export default YuGiOhIndexPage
